import 'core-js/features/string/replace-all';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SiteNotFound } from '@homeplay/containers';
import { FrameExit } from '@homeplay/components';
import './index.scss';
import AppWrap from './AppWrap';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path={'/'} element={<SiteNotFound />} />
      <Route path={'/exit'} element={<FrameExit />} />
      <Route path="/:site/*" element={<AppWrap />} />
    </Routes>
  </BrowserRouter>
);

if (
  window.location.href.includes('touchscreen') ||
  sessionStorage.getItem('hp-touchscreen')
) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register();
}
