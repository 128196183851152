import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '@homeplay/components';
import {
  Auth,
  BonusGames,
  CashIn,
  CashOut,
  EditPhone,
  EditProfile,
  Game,
  InfoPage,
  Profile,
  ResetPassword,
  Statement,
  Verification,
} from '@homeplay/containers';
import {
  SiteContextProvider,
  UnitSocketProvider,
  autoLogin,
  loadUnitFail,
  selectHHConfigs,
  selectIsRegistrationRequired,
  selectIsVerificationEnabled,
  selectUser,
} from '@homeplay/app';
import MainPage from './containers/MainPage';

function App() {
  const location = useLocation();
  const { site: siteName } = useParams();
  const dispatch = useDispatch();
  const hhConfigs = useSelector(selectHHConfigs);
  const user = useSelector(selectUser);
  const isVerificationEnabled = useSelector(selectIsVerificationEnabled);
  const isRegistrationRequired = useSelector(selectIsRegistrationRequired);

  let isTouchUnit = false;
  const query = new URLSearchParams(location.search);
  for (let param of query.entries()) {
    if (param[0] === 'touchscreen') {
      isTouchUnit = true;
    }
  }
  if (isTouchUnit) {
    sessionStorage.setItem('hp-touchscreen', '1');
  } else if (sessionStorage.getItem('hp-touchscreen')) {
    isTouchUnit = true;
  }

  useEffect(() => {
    const site = siteName.charAt(0).toUpperCase() + siteName.slice(1);
    const baseUrl = window.location.origin;
    const dynamicManifest = {
      short_name: site,
      name: site,
      icons: [
        {
          src: `${baseUrl}/logo57pwa.png`,
          type: 'image/png',
          sizes: '57x57',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo60pwa.png`,
          type: 'image/png',
          sizes: '60x60',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo72pwa.png`,
          type: 'image/png',
          sizes: '72x72',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo76pwa.png`,
          type: 'image/png',
          sizes: '76x76',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo114pwa.png`,
          type: 'image/png',
          sizes: '114x114',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo120pwa.png`,
          type: 'image/png',
          sizes: '120x120',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo144pwa.png`,
          type: 'image/png',
          sizes: '144x144',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo152pwa.png`,
          type: 'image/png',
          sizes: '152x152',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo180pwa.png`,
          type: 'image/png',
          sizes: '180x180',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo192pwa.png`,
          type: 'image/png',
          sizes: '192x192',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo32pwa.png`,
          type: 'image/png',
          sizes: '32x32',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo96pwa.png`,
          type: 'image/png',
          sizes: '96x96',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo16pwa.png`,
          type: 'image/png',
          sizes: '16x16',
          purpose: 'any maskable',
        },
        {
          src: `${baseUrl}/logo512pwa.png`,
          type: 'image/png',
          sizes: '512x512',
          purpose: 'any maskable',
        },
      ],
      start_url: `${baseUrl}/${siteName}`,
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector('#manifest-placeholder')
      .setAttribute('href', manifestURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO temporary solution
    if (siteName === 'dalila' && !isTouchUnit) {
      dispatch(
        loadUnitFail({
          error: { message: 'This site is not active now.', redirect: '/' },
        })
      );
    } else {
      dispatch(autoLogin(siteName, isTouchUnit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteName]);

  const getGameLink = (loadParams) => {
    return `${process.env.REACT_APP_PTC_BASE_URL}/${loadParams.path}?hash=${loadParams.hash}&curr=ZAR&w=w1&lang=en&config=1861&exit=${window.location.origin}/exit`;
  };

  return (
    <SiteContextProvider siteName={siteName} isTouchUnit={isTouchUnit}>
      <UnitSocketProvider siteName={siteName} isTouchUnit={isTouchUnit}>
        <Layout>
          <Routes>
            <Route path={'/'} element={<MainPage />} />
            <Route
              path={'ptc/game/:gameSlug'}
              element={<Game getGameLink={getGameLink} />}
            />
            <Route path={'auth'} element={<Auth />} />
            <Route path={'reset-password'} element={<ResetPassword />} />
            {(user || !isRegistrationRequired) && (
              <Route path={'cashin/*'} element={<CashIn />} />
            )}
            <Route path={'cashout/*'} element={<CashOut />} />
            {!!hhConfigs && (
              <Route path={'happy-hour'} element={<InfoPage />} />
            )}
            {!!hhConfigs && (
              <Route path={'bonus-games'} element={<BonusGames />} />
            )}
            {!!user && <Route path={'profile'} element={<Profile />} />}
            {!!user && <Route path={'statement'} element={<Statement />} />}
            {!!user && (
              <Route path={'edit-profile'} element={<EditProfile />} />
            )}
            {!!user && <Route path={'edit-phone'} element={<EditPhone />} />}
            {!!user && isVerificationEnabled && !isTouchUnit && (
              <Route path={'verification/*'} element={<Verification />} />
            )}
            <Route path="*" element={<Navigate to=".." replace />} />
          </Routes>
        </Layout>
      </UnitSocketProvider>
    </SiteContextProvider>
  );
}

export default App;
