import React from 'react';
import {
  Footer,
  MainHeader,
  GamesList,
  InfoBanner,
} from '@homeplay/components';
import { selectHhSchedules, selectPtcGames } from '@homeplay/app';
import classes from './MainPage.module.scss';
import { useSelector } from 'react-redux';
import logoPTC from '../../assets/images/ptc-logo.png';

const MainPage = () => {
  const ptcGames = useSelector(selectPtcGames);
  const { nearestHh } = useSelector(selectHhSchedules);

  return (
    <React.Fragment>
      <MainHeader />
      <main className={classes.MainMenu}>
        {nearestHh && <InfoBanner texts={nearestHh.texts} />}

        <div className="container">
          <img
            className={classes.Logo}
            src={logoPTC}
            alt="Palm Treasures Club"
            width="237"
            height="144"
          />
          {ptcGames && <GamesList games={ptcGames} />}
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default MainPage;
